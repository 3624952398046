var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: _vm.$t("lbl_invoices") } },
                    [
                      _c(
                        "a-row",
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "a-form",
                                _vm._b(
                                  {
                                    attrs: {
                                      layout: "vertical",
                                      form: _vm.form
                                    }
                                  },
                                  "a-form",
                                  _vm.formItemLayout,
                                  false
                                ),
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            span: 12,
                                            xs: 24,
                                            sm: 24,
                                            md: 12
                                          }
                                        },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.branch.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.branch
                                                          .decorator,
                                                      expression:
                                                        "formRules.branch.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name: _vm.$t(
                                                      _vm.formRules.branch.name
                                                    ),
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.branch
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading: _vm.loading.branch,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getBranch(
                                                        value
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(_vm.dataBranch, function(
                                                  data,
                                                  index
                                                ) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: index,
                                                      attrs: { value: data.id }
                                                    },
                                                    [
                                                      _c(
                                                        "a-tooltip",
                                                        [
                                                          _c(
                                                            "template",
                                                            { slot: "title" },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    data.name
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                data.name
                                                              ) +
                                                              " "
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ],
                                                    1
                                                  )
                                                }),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.source.label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules.source
                                                          .decorator,
                                                      expression:
                                                        "formRules.source.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name: _vm.$t(
                                                      _vm.formRules.source.name
                                                    ),
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.source
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading: _vm.loading.source,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getSource(
                                                        value
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataListSource,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.value
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.value
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.value
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.customerName
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .customerName
                                                          .decorator,
                                                      expression:
                                                        "formRules.customerName.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name: _vm.$t(
                                                      _vm.formRules.customerName
                                                        .name
                                                    ),
                                                    placeholder: _vm.$t(
                                                      _vm.formRules.customerName
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading.customer,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getCustomerName(
                                                        value
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataCustomer,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.name
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            span: 12,
                                            xs: 24,
                                            sm: 24,
                                            md: 12
                                          }
                                        },
                                        [
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.invoiceDateFrom
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-date-picker", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .invoiceDateFrom
                                                        .decorator,
                                                    expression:
                                                      "formRules.invoiceDateFrom.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    _vm.formRules
                                                      .invoiceDateFrom
                                                      .placeholder
                                                  ),
                                                  format:
                                                    _vm.DEFAULT_DATE_FORMAT
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.invoiceDateTo
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c("a-date-picker", {
                                                directives: [
                                                  {
                                                    name: "decorator",
                                                    rawName: "v-decorator",
                                                    value:
                                                      _vm.formRules
                                                        .invoiceDateTo
                                                        .decorator,
                                                    expression:
                                                      "formRules.invoiceDateTo.decorator"
                                                  }
                                                ],
                                                staticClass: "w-100",
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    _vm.formRules.invoiceDateTo
                                                      .placeholder
                                                  ),
                                                  format:
                                                    _vm.DEFAULT_DATE_FORMAT
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.invoiceNumber
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .invoiceNumber
                                                          .decorator,
                                                      expression:
                                                        "formRules.invoiceNumber.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name: _vm.$t(
                                                      _vm.formRules
                                                        .invoiceNumber.name
                                                    ),
                                                    placeholder: _vm.$t(
                                                      _vm.formRules
                                                        .invoiceNumber
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "filter-option": false,
                                                    loading:
                                                      _vm.loading.invoiceNumber,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search:
                                                      _vm.onSearchInvoiceNo
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataInvoiceNumber,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.id
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.name
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.name
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "a-form-model-item",
                                            {
                                              attrs: {
                                                label: _vm.$t(
                                                  _vm.formRules.invoiceStatus
                                                    .label
                                                )
                                              }
                                            },
                                            [
                                              _c(
                                                "a-select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "decorator",
                                                      rawName: "v-decorator",
                                                      value:
                                                        _vm.formRules
                                                          .invoiceStatus
                                                          .decorator,
                                                      expression:
                                                        "formRules.invoiceStatus.decorator"
                                                    }
                                                  ],
                                                  attrs: {
                                                    name: _vm.$t(
                                                      _vm.formRules
                                                        .invoiceStatus.name
                                                    ),
                                                    placeholder: _vm.$t(
                                                      _vm.formRules
                                                        .invoiceStatus
                                                        .placeholder
                                                    ),
                                                    showSearch: true,
                                                    "option-filter-prop":
                                                      "children",
                                                    "filter-option":
                                                      _vm.filterOption,
                                                    loading:
                                                      _vm.loading.invoiceStatus,
                                                    allowClear: true
                                                  },
                                                  on: {
                                                    search: function(value) {
                                                      return _vm.getInvoiceStatus(
                                                        value
                                                      )
                                                    }
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.dataInvoiceStatus,
                                                  function(data, index) {
                                                    return _c(
                                                      "a-select-option",
                                                      {
                                                        key: index,
                                                        attrs: {
                                                          value: data.value
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-tooltip",
                                                          [
                                                            _c(
                                                              "template",
                                                              { slot: "title" },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      data.value
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  data.value
                                                                ) +
                                                                " "
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-space",
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loading.find
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickButtonTable("find")
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("lbl_find")))]
                                  ),
                                  _vm.$can("create", "invoice-ar")
                                    ? _c(
                                        "a-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.clickButtonTable(
                                                "create"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("lbl_create_new"))
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { staticClass: "mt-4", attrs: { span: 24 } },
                            [
                              _c("TableCustom", {
                                attrs: {
                                  onSelectChange: _vm.onSelectChange,
                                  selectedRowKeys: _vm.selectRow.header,
                                  dataSource: _vm.dataTable.dataSource,
                                  columns: _vm.columnsTable,
                                  scroll: {
                                    x: "calc(700px + 50%)",
                                    y: "700px"
                                  },
                                  paginationcustom: true,
                                  defaultPagination: false,
                                  loading: _vm.loading.find
                                },
                                on: {
                                  "on-view": _vm.responseViewTable,
                                  "on-columnClicked": _vm.handleClickColumn
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "a-space",
                                {
                                  staticStyle: { height: "50px" },
                                  attrs: { align: "center" }
                                },
                                [
                                  _c("Pagination", {
                                    attrs: {
                                      total: _vm.dataTable.totalData,
                                      pageSizeSet: _vm.limit,
                                      pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
                                    },
                                    on: {
                                      "response-pagesize-change":
                                        _vm.responsePageSizeChange,
                                      "response-currentpage-change":
                                        _vm.responseCurrentPageChange
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "mt-3",
                              attrs: { span: 12, align: "end" }
                            },
                            [
                              _c(
                                "a-tag",
                                {
                                  staticStyle: { "font-size": "13px" },
                                  attrs: { color: "grey" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("lbl_total_found")) +
                                      " : " +
                                      _vm._s(_vm.dataTable.totalData) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticClass: "mt-3",
                              attrs: { span: 24, align: "end" }
                            },
                            [
                              _vm.$can("approve", "invoice-ar")
                                ? _c(
                                    "a-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loading.posting
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickButtonTable("posting")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("lbl_posting")) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.$can("print", "invoice-ar")
                                ? _c(
                                    "a-button",
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        type: "primary",
                                        loading: _vm.loading.print
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.clickButtonTable("print")
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("lbl_print_receipt_document")
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 500,
            title: "Posting",
            showmodal: _vm.modal.posting
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c(
                "a-form",
                _vm._b(
                  { attrs: { layout: "vertical", form: _vm.formPosting } },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.invoiceReceivedDate.label
                                )
                              }
                            },
                            [
                              _c("a-date-picker", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.invoiceReceivedDate
                                        .decorator,
                                    expression:
                                      "formRules.invoiceReceivedDate.decorator"
                                  }
                                ],
                                staticClass: "w-100",
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.invoiceReceivedDate
                                      .placeholder
                                  ),
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  "disabled-date": _vm.disabledDate
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.picCustomerName.label
                                )
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.picCustomerName.decorator,
                                    expression:
                                      "formRules.picCustomerName.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.picCustomerName.placeholder
                                  ),
                                  autocomplete: "off"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.cancelModal("posting")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.approve },
                  on: { click: _vm.approve }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_approve")) + " ")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: 800,
            title: "Print",
            showmodal: _vm.modal.print,
            idmodal: "modal1"
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c(
                "a-form",
                _vm._b(
                  { attrs: { layout: "vertical", form: _vm.formPrint } },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.customerName.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.customerName.decorator,
                                    expression:
                                      "formRules.customerName.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.customerName.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.billToAddress.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.billToAddress.decorator,
                                    expression:
                                      "formRules.billToAddress.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.billToAddress.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.branch.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.branch.decorator,
                                    expression: "formRules.branch.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.branch.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "mt-4", attrs: { span: 24 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addRow()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_add_row")))]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "danger" },
                                  on: {
                                    click: function($event) {
                                      return _vm.showConfirmation()
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("lbl_delete_row")))]
                              )
                            ],
                            1
                          ),
                          _c("TableCustom", {
                            attrs: {
                              idtable: "table1",
                              dataSource: _vm.dataTable.print,
                              columns: _vm.columnsTablePrint,
                              scroll: { x: "calc(700px + 50%)", y: 400 },
                              paginationcustom: false,
                              defaultPagination: true,
                              selectedRowKeys: _vm.selectRow.modal,
                              onSelectChange: _vm.onSelectChangeModal,
                              handleInput: _vm.handleInput
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.cancelModal("print")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.printReceipt },
                  on: { click: _vm.handlePrintReceipt }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "Modal",
        {
          attrs: {
            centered: false,
            dialogstyle: { top: "20px" },
            maskClosable: false,
            width: "60%",
            title: _vm.$t("lbl_invoice_ar_view"),
            showmodal: _vm.modal.detail
          }
        },
        [
          _c(
            "div",
            { attrs: { slot: "contentmodal" }, slot: "contentmodal" },
            [
              _c(
                "a-form",
                _vm._b(
                  { attrs: { layout: "vertical", form: _vm.formModalDetail } },
                  "a-form",
                  _vm.formItemLayout,
                  false
                ),
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.invoiceNumber.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.invoiceNumber.decorator,
                                    expression:
                                      "formRules.invoiceNumber.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.picCustomerName.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.invoiceDate.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.invoiceDate.decorator,
                                    expression:
                                      "formRules.invoiceDate.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.invoiceDate.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.taxType.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.taxType.decorator,
                                    expression: "formRules.taxType.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.taxType.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: { label: _vm.$t(_vm.formRules.top.label) }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.top.decorator,
                                    expression: "formRules.top.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.top.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.currency.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.currency.decorator,
                                    expression: "formRules.currency.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.currency.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.poNumber.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.poNumber.decorator,
                                    expression: "formRules.poNumber.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.poNumber.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(
                                  _vm.formRules.referenceNumber.label
                                )
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value:
                                      _vm.formRules.referenceNumber.decorator,
                                    expression:
                                      "formRules.referenceNumber.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.referenceNumber.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.invoiceTaxNo.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.invoiceTaxNo.decorator,
                                    expression:
                                      "formRules.invoiceTaxNo.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.invoiceTaxNo.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.customerName.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.customerName.decorator,
                                    expression:
                                      "formRules.customerName.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.customerName.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.shipTo.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.shipTo.decorator,
                                    expression: "formRules.shipTo.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.shipTo.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.usageType.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.usageType.decorator,
                                    expression: "formRules.usageType.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.usageType.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRules.description.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRules.description.decorator,
                                    expression:
                                      "formRules.description.decorator"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    _vm.formRules.description.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: true
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("TableCustom", {
                            attrs: {
                              idtable: "table1",
                              dataSource: _vm.dataTable.detail,
                              columns: _vm.columnsTableDetail,
                              scroll: { x: "calc(300px + 50%)", y: 700 },
                              paginationcustom: false,
                              defaultPagination: false,
                              size: "small"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "mt-2", attrs: { span: 24 } },
                        [
                          _c(
                            "a-col",
                            { attrs: { span: 14, offset: 11 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.subTotal.label)
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.subTotal.decorator,
                                        expression:
                                          "formRules.subTotal.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.$t(_vm.formRules.subTotal.name),
                                      placeholder: _vm.$t(
                                        _vm.formRules.subTotal.placeholder
                                      ),
                                      autocomplete: "off",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 14, offset: 11 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.discount.label)
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.discount.decorator,
                                        expression:
                                          "formRules.discount.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.$t(_vm.formRules.discount.name),
                                      placeholder: _vm.$t(
                                        _vm.formRules.discount.placeholder
                                      ),
                                      autocomplete: "off",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 14, offset: 11 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRules.baseAmount.label
                                    )
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value:
                                          _vm.formRules.baseAmount.decorator,
                                        expression:
                                          "formRules.baseAmount.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.$t(
                                        _vm.formRules.baseAmount.name
                                      ),
                                      placeholder: _vm.$t(
                                        _vm.formRules.baseAmount.placeholder
                                      ),
                                      autocomplete: "off",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 14, offset: 11 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.vat.label)
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.vat.decorator,
                                        expression: "formRules.vat.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.$t(_vm.formRules.vat.name),
                                      placeholder: _vm.$t(
                                        _vm.formRules.vat.placeholder
                                      ),
                                      autocomplete: "off",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            { attrs: { span: 14, offset: 11 } },
                            [
                              _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(_vm.formRules.total.label)
                                  }
                                },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.total.decorator,
                                        expression: "formRules.total.decorator"
                                      }
                                    ],
                                    attrs: {
                                      name: _vm.$t(_vm.formRules.total.name),
                                      placeholder: _vm.$t(
                                        _vm.formRules.total.placeholder
                                      ),
                                      autocomplete: "off",
                                      disabled: true
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footermodal" }, slot: "footermodal" },
            [
              _c(
                "a-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.cancelModal("detail")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }